import React, { lazy, Suspense } from 'react'

export const menuFront = [
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', icon: 'fe fe-phone-call', disabled: true, },
    {
        key: 'passage',
        title: 'THE PASSAGE',
        url: '/web/passage',
      },
      {
        key: 'promotion',
        title: 'โปรโมชั่น',
        url: '/web/promotion',
      },
      {
        key: 'video',
        title: 'วีดีโอ',
        url: '/web/video',
      },
      {
        key: 'service',
        title: 'บริการ',
        url: '/web/service',
      },
      {
        key: 'about',
        title: 'เกี่ยวกับเรา',
        url: '/web/about',
      },
      {
        key: 'contact',
        title: 'ติดต่อเรา',
        url: '/web/contact',
      },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // {
    //     title: 'Dashboards',
    //     key: 'dashboards',
    //     icon: 'fe fe-home',
    //     roles: ['admin'], 
    //     count: 8,
    //     children: [
    //     {
    //         title: 'Test',
    //         key: 'dashboardTest',
    //         url: '/dashboard/test',
    //     },
    //     ]
    // },
]

export const menuBackConfig = {
    menuBackground: {backgroundColor:"#222323"},
    menuColor1: {color:"#FFF"},
    menuColor2: {color:"#CCC"},
}
export const menuBack = {
    my: {
        title: '',
        roles: [ 'admin'],
        status: 'active',
        menu: [
            { sector: "sector1", title: "My" },
            { icon: "fe fe-file-text", to: "../userMyProfile", title: "My Profile" },
            // { icon: "fe fe-chevron-right", to: "../myGold", title: "My Gold / Point" },
            // { icon: "fe fe-chevron-right", to: "../paymentList", title: "Gold Saving Payment1" },
            // { icon: "fe fe-chevron-right", to: "../housedList", title: "Lead / Sales Page" },
            // { icon: "fe fe-chevron-right", to: "../housedOrderList", title: "รายการขาย" },
            // { icon: "fe fe-chevron-right", to: "../leadPageOrderList", title: "ลูกค้าลงทะเบียน" },
            // { icon: "fe fe-chevron-right", to: "../housedRegisterList", title: "ลูกค้าลงทะเบียน" },
            // { icon: "fe fe-chevron-right", to: "../myCredit", title: "My Credit / Point" },
            // { icon: "fe fe-chevron-right", to: "../mySaleProductSharingList", title: "Credit / Point Tran.." },
        ],
    },
    dev: {
        title: 'Dev',
        roles: [ 'dev'],
        status: 'active',
        menu: [
            { icon: "fe fe-users", to: "../userList", title: "User Management" },
            { icon: "fe fe-chevron-right", to: "../homeSlideList", title: "Home Slide" },
            { icon: "fe fe-chevron-right", to: "../productList", title: "Product" },
            { icon: "fe fe-chevron-right", to: "../videoList", title: "Video" },
            { icon: "fe fe-chevron-right", to: "../videoGroupList", title: "Video Group" },
            { icon: "fe fe-chevron-right", to: "../promotionList", title: "Promotion" },
            { icon: "fe fe-chevron-right", to: "../promotionGroupList", title: "Promotion Group" },
            { icon: "fe fe-chevron-right", to: "../registerLeadList", title: "Customer Register" },
            { icon: "fe fe-chevron-right", to: "../registerServiceList", title: "Register Service" },
            { icon: "fe fe-chevron-right", to: "../budgetList", title: "Budget" },
            { icon: "fe fe-chevron-right", to: "../serviceTypeList", title: "Service Type" },
            { icon: "fe fe-chevron-right", to: "../projectImageList", title: "Project Image" },
        ],
    },
    office: {
        title: 'Office',
        roles: [ 'admin'],
        status: 'inActive',
        menu: [
            { icon: "fe fe-chevron-right", to: "../userPointList", title: "Member Credit/Point" },
            { icon: "fe fe-chevron-right", to: "../saleProductList", title: "Member (Profit Sharing)" },
            { icon: "fe fe-chevron-right", to: "../saleProductSharingList", title: "Credit / Point Tran.." },
            // { icon: "fe fe-chevron-right", to: "../", title: "" },
            // { icon: "fe fe-chevron-right", to: "../", title: "" },
        ],
    },
    admin: {
        title: '',
        roles: [ 'admin'],
        status: 'active',
        menu: [
            { sector: "sector2", title: "Registration" },
            { icon: "fe fe-chevron-right", to: "../registerLeadList", title: "Customer Register" },
            { icon: "fe fe-chevron-right", to: "../registerServiceList", title: "Register Service" },
            { sector: "sector3", title: "Admin" },
            { icon: "fe fe-chevron-right", to: "../homeSlideList", title: "Home Slide" },
            // { icon: "fe fe-chevron-right", to: "../productList", title: "Product" },
            { icon: "fe fe-chevron-right", to: "../videoList", title: "Video" },
            // { icon: "fe fe-chevron-right", to: "../videoGroupList", title: "Video Group" },
            { icon: "fe fe-chevron-right", to: "../promotionList", title: "Promotion" },
            // { icon: "fe fe-chevron-right", to: "../promotionGroupList", title: "Promotion Group" },
            // { icon: "fe fe-chevron-right", to: "../budgetList", title: "Budget" },
            // { icon: "fe fe-chevron-right", to: "../serviceTypeList", title: "Service Type" },
            // { icon: "fe fe-chevron-right", to: "../projectImageList", title: "Project Image" },
        ],
    },
}

export const route = [
    
  // dynamic
  { path: '/devInit', Component: lazy(() => import('app/_custom/pages/zystem/devInit'))},
  { path: '/masterList', Component: lazy(() => import('app/_custom/pages/master/list'))},
  { path: '/master/:id', Component: lazy(() => import('app/_custom/pages/master/edit'))},
  // user
  // { path: '/userList', Component: lazy(() => import('app/_custom/pages/user/list'))},
  // { path: '/user/:id', Component: lazy(() => import('app/_custom/pages/user/edit'))},
  // Custom
  { path: '/userMyProfile', Component: lazy(() => import('app/_custom/pages/userMyProfile')), exact: true,},


  // project/housed/ PROJECT ///////////////////////////////////////////////////////////////

  { path: '/web/thankYou', Component: lazy(() => import('app/housed/pages/web/thankYou'))},
  { path: '/web/privacy', Component: lazy(() => import('app/housed/pages/web/privacy'))},
  // home
  { path: '/web/video/:id', Component: lazy(() => import('app/housed/pages/web/videoDetail'))},
  { path: '/web/promotion/:id', Component: lazy(() => import('app/housed/pages/web/promotionDetail'))},


  { path: '/web/home', Component: lazy(() => import('app/housed/pages/web/home'))},
  { path: '/web/homeRegister', Component: lazy(() => import('app/housed/pages/web/homeRegister'))},
  { path: '/home/homeRegister', Component: lazy(() => import('app/housed/pages/web/homeRegister'))},
  { path: '/web/about', Component: lazy(() => import('app/housed/pages/web/about'))},
  { path: '/web/contact', Component: lazy(() => import('app/housed/pages/web/contact'))},
  { path: '/web/promotion', Component: lazy(() => import('app/housed/pages/web/promotion'))},
  { path: '/web/salePromotion', Component: lazy(() => import('app/housed/pages/web/salePromotion'))},
  { path: '/web/servicePromotion', Component: lazy(() => import('app/housed/pages/web/servicePromotion'))},
  { path: '/web/service', Component: lazy(() => import('app/housed/pages/web/service'))},
  { path: '/web/video', Component: lazy(() => import('app/housed/pages/web/video'))},
  { path: '/web/passage', Component: lazy(() => import('app/housed/pages/web/passage'))},

  { path: '/web/blog', Component: lazy(() => import('app/housed/pages/web/blog'))},

  


  // admin
  { path: '/blogList', Component: lazy(() => import('app/housed/pages/blog/list'))},
  { path: '/blog/:id', Component: lazy(() => import('app/housed/pages/blog/edit'))},

  { path: '/blogGroupList', Component: lazy(() => import('app/housed/pages/blogGroup/list'))},
  { path: '/blogGroup/:id', Component: lazy(() => import('app/housed/pages/blogGroup/edit'))},

  { path: '/homeSlideList', Component: lazy(() => import('app/housed/pages/homeSlide/list'))},
  { path: '/homeSlide/:id', Component: lazy(() => import('app/housed/pages/homeSlide/edit'))},

  { path: '/productList', Component: lazy(() => import('app/housed/pages/product/list'))},
  { path: '/product/:id', Component: lazy(() => import('app/housed/pages/product/edit'))},

  { path: '/videoList', Component: lazy(() => import('app/housed/pages/video/list'))},
  { path: '/video/:id', Component: lazy(() => import('app/housed/pages/video/edit'))},

  { path: '/videoGroupList', Component: lazy(() => import('app/housed/pages/videoGroup/list'))},
  { path: '/videoGroup/:id', Component: lazy(() => import('app/housed/pages/videoGroup/edit'))},

  { path: '/promotionList', Component: lazy(() => import('app/housed/pages/promotion/list'))},
  { path: '/promotion/:id', Component: lazy(() => import('app/housed/pages/promotion/edit'))},

  { path: '/promotionGroupList', Component: lazy(() => import('app/housed/pages/promotionGroup/list'))},
  { path: '/promotionGroup/:id', Component: lazy(() => import('app/housed/pages/promotionGroup/edit'))},

  { path: '/registerLeadList', Component: lazy(() => import('app/housed/pages/registerLead/list'))},
  { path: '/registerLead/:id', Component: lazy(() => import('app/housed/pages/registerLead/edit'))},

  { path: '/registerServiceList', Component: lazy(() => import('app/housed/pages/registerService/list'))},
  { path: '/registerService/:id', Component: lazy(() => import('app/housed/pages/registerService/edit'))},

  { path: '/budgetList', Component: lazy(() => import('app/housed/pages/budget/list'))},
  { path: '/budget/:id', Component: lazy(() => import('app/housed/pages/budget/edit'))},

  { path: '/serviceTypeList', Component: lazy(() => import('app/housed/pages/serviceType/list'))},
  { path: '/serviceType/:id', Component: lazy(() => import('app/housed/pages/serviceType/edit'))},

  { path: '/projectImageList', Component: lazy(() => import('app/housed/pages/projectImage/list'))},
  { path: '/projectImage/:id', Component: lazy(() => import('app/housed/pages/projectImage/edit'))},





  // admin
  { path: '/blogList', Component: lazy(() => import('app/gentlestots/pages/blog/list'))},
  { path: '/blog/:id', Component: lazy(() => import('app/gentlestots/pages/blog/edit'))},

  { path: '/blogGroupList', Component: lazy(() => import('app/gentlestots/pages/blogGroup/list'))},
  { path: '/blogGroup/:id', Component: lazy(() => import('app/gentlestots/pages/blogGroup/edit'))},

  { path: '/contactRegisterList', Component: lazy(() => import('app/gentlestots/pages/contactRegister/list'))},
  { path: '/contactRegister/:id', Component: lazy(() => import('app/gentlestots/pages/contactRegister/edit'))},

  // userMyProfile

  // { path: '/companyList', Component: lazy(() => import('app/housed/pages/company/list'))},
  // { path: '/company/:id', Component: lazy(() => import('app/housed/pages/company/edit'))},

  // RIS PROJECT ///////////////////////////////////////////////////////////////
  // // my
  // { path: '/myCredit', Component: lazy(() => import('app/housed/pages/my/myCredit'))},
  // user
  { path: '/userList', Component: lazy(() => import('app/housed/pages/user/list'))},
  { path: '/user/:id', Component: lazy(() => import('app/housed/pages/user/edit'))},

  
]