import React from 'react'

const projectConfig = {
  website: 'www.weget.co.th',
  appName: 'housed',
  appFullName: 'House Development',
  appCopyright: `Copyright © 2015-${new Date().getFullYear()} House Development`,
  companyName: 'House Development Co.,Ltd.',
  appTitle: 'House Development',
  appDesc: '',
  
  mainColor: {
    color1:'#d80218',
    color2:'#cf1f2f',
  },

  appLogo: <img alt="logo" src="app/housed/logo/android-chrome-512x512.png" style={{ maxWidth: '40px' }}/>,
  appLogo2: <img alt="logo" src="app/housed/logo/android-chrome-512x512.png" style={{ maxHeight: '60px' }}/>,

  appLogoWidth: <img alt="logo" src="app/housed/logo/logo_width.png" style={{ height: '40px' }}/>,
  appLogo2Width: <img alt="logo" src="app/housed/logo/logo_width.png" style={{ maxHeight: '60px', maxWidth: '200px', alignItems: 'center' }}/>,

  socialMedia: {
    phone: <a href="tel:02 171 0000" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#000"}}><i className="fas fa-3x fa-phone-square"></i></a>,
    // website: <a href="http://www.house-d.co.th" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#000"}}><i className="fas fa-3x fa-globe"></i></a>,
    // line  : <a href="http://line.me/ti/p/~%40bzz0674k" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#00c300"}}><i className="fab fa-3x fa-line"></i></a>,
    line  : <a href="http://line.me/ti/p/@housed" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#00c300"}}><i className="fab fa-3x fa-line"></i></a>,
    facebook: <a href="https://www.facebook.com/housedeevelopment/" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#3b5998"}}><i className="fab fa-3x fa-facebook-square"></i></a>,
    youtube: <a href="https://www.youtube.com/channel/UCcT1Z6PRZHE0Epm9VQiF9gQ" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#FF0000"}}><i className="fab fa-3x fa-youtube-square"></i></a>,
    instagram: <a href="https://www.instagram.com/housedeevelopment/" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#9b6954"}}><i className="fab fa-3x fa-instagram"></i></a>,
    // lazada: <a href="https://www.lazada.co.th/shop/gentle-and-natural/?spm=a2o4m.pdp.seller.1.43d5559bxNsFZD&itemId=1317324711&channelSource=pdp" target="_blank" rel="noopener noreferrer"><img src="etc/logos/social/logo_lazada.webp" style={{marginLeft: "15px", marginTop:"-40px"}} width="62px" alt="social" /></a>,
    // shopee: <a href="https://shopee.co.th/gentles_tots?categoryId=58&itemId=6441237640" target="_blank" rel="noopener noreferrer"><img src="etc/logos/social/logo_shopee.webp" style={{marginLeft: "15px", marginTop:"-40px"}} width="62px" alt="social" /></a>,
  },

  // <a href="http://line.me/ti/p/~%40bzz0674k" target="_blank" rel="noopener noreferrer"><img src="etc/logos/social/logo_line.webp" style={{marginLeft: "15?px", marginTop:"-23px"}} width="40px" alt="social" /></a>
  // {/* <a href="http://" target="_blank" rel="noopener noreferrer"><i className="fa fas-3x fa-line"></i></a> */}
  // <a href="https://www.facebook.com/housedeevelopment/" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#3b5998"}}><i className="fab fa-3x fa-facebook-square"></i></a>
  // <a href="https://www.youtube.com/channel/UCcT1Z6PRZHE0Epm9VQiF9gQ" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#FF0000"}}><i className="fab fa-3x fa-youtube-square"></i></a>
  // <a href="https://www.instagram.com/housedeevelopment/" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#9b6954"}}><i className="fa fas-3x fa-instagram"></i></a>
  

  frontOffice: 'active', // active / inActive

  facebook : {
    appId: '474812183840866', // default ball facebook dev app name = React Web Chat Integration

    // config at https://www.facebook.com/wegetcompany/settings/?tab=messaging
    pageId: '312562565829477', // housed
    // pageId: '391079311372123', // weget
    // pageId: '306725926676739', // upbrand
    // pageId: '2003917883268443', // gentlestots
    
  },
  googleAnalytic : {
    // tracking_id : 'UA-120432092-1', // example
    tracking_id : 'UA-120432092-1', // housed
  }
}
export default projectConfig 